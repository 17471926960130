import React from 'react'

const Cancel = () => {
  return (
    <div className='font-montSerrat w-full h-full flex flex-col items-center justify-center py-[10rem]'>
    <h1 className='text-[28px] lg:text-[64px] font-bold text-center'>OPERATIUNEA A FOST ANULATA </h1>


</div>
  )
}

export default Cancel